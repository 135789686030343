import React, { useState } from "react"
import { navigate } from "gatsby"
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { SiteContext, ContextProviderComponent } from "../context/mainContext"
import { DENOMINATION } from "../../providers/inventoryProvider"
import { FaLongArrowAltLeft } from "react-icons/fa"
import Image from "../components/Image"
import uuid from "uuid/v4"
import LogLink from "../components/LogLink"
import { getLocalStoredString } from '../../utils/helpers'


import { setEventProperties, setPageProperties,isBrowser,setUserProperties } from "../../utils/zeotap"
import { isSymphony } from "../../utils/helpers"
import { nanoid } from "nanoid"
import ConditionalLink from "../components/ConditionalLink"
import { getSiteConfigField } from '../../utils/helpers';
import { compose, values, map, groupBy, prop } from 'ramda'

const GUEST_FLAG = "GUEST_LOGIN"


function CheckoutWithContext(props) {
  if (isGuest() && isSymphony()) {
    showPopup()
  }
  setPageProperties({page_category:'Checkout',page_name:'Checkout',page_domain:isBrowser() && window.location.hostname})
  return (
    <ContextProviderComponent>
      <SiteContext.Consumer>
        {context => (
            <Checkout {...props} context={context} />
        )}
      </SiteContext.Consumer>
    </ContextProviderComponent>
  )
}

const calculateShipping = () => {
  return 0
}

const Input = ({disabled, onChange, value, name, placeholder }) => (
  <input
    disabled={disabled}
    onChange={onChange}
    value={value}
    className="mt-2 text-sm shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    type="text"
    placeholder={placeholder}
    name={name}
    aria-label="name"
  />
)
const isGuest = () => {const storageKeyObject = JSON.parse(getLocalStoredString('GATSBY_ECOMMERCE_STARTER_')); return storageKeyObject ? !storageKeyObject['signedIn'] : true;}

const calculateDiscount = (subTotal, discount) => {
  discount = +discount.slice(-2);
  if (discount > 0 && discount < 100)
    return Math.round(subTotal * discount * 0.01)
  return 0;
}

const showPopup = () => isBrowser() && isSymphony() && (!window.sessionStorage.getItem(GUEST_FLAG)) && confirmAlert({
  message: 'Do you want to Login ?',
  buttons: [
    {
      label: 'Login',
      onClick: () => navigate('/login')
    },
    {
      label: 'Proceed as Guest',
      onClick: () => {window.sessionStorage.setItem(GUEST_FLAG,'true'); return;}
    }
  ]
});

const calculateTotal = (subTotal, discount) => Math.round(subTotal - calculateDiscount(subTotal, discount) + calculateShipping());

const Checkout = ({ context }) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const [orderCompleted, setOrderCompleted] = useState(false)
  const [input, setInput] = useState({
    name: "",
    email: "",
    payment_mode: isSymphony() ? "Cash On Delivery" : "",
    address: "Zeotap, Bangalore",
    discount: ""
  })
  const [billingAmount, setBillingAmount] = useState({total:  context.total, discount: 0})

  const onChange = e => {
    setErrorMessage(null)
    setInput({ ...input, [e.target.name]: e.target.value })
    if(e.target.name === 'discount') {
      setBillingAmount({total: calculateTotal(context.total, e.target.value), discount: calculateDiscount(context.total, e.target.value)});
    }
  }


  const handleSubmit = async event => {
    setEventProperties("confirmOrderClicked")
    event.preventDefault()
    const { name, email, address, payment_mode, discount } = input
    const { total, clearCart, cart } = context
    const event_id = nanoid()
    // window.fbq('track', 'Purchase', {
    //   event_id, eventID: event_id, name, email, address, payment_mode, discount
    // });
    // Validate input
    if (!name || (!email && isGuest()) || !address || !payment_mode) {
      setErrorMessage("Please fill in the form!")
      return
    }

    const order = {
      name,
      email,
      address,
      total,
      payment_mode: payment_mode,
      receipt_email: "customer@example.com",
      id: uuid(),
    }

    if (isGuest()) {
      setUserProperties({ email: email })
    }
    const items = compose(values, map(list => ({
      productID: list?.[0].id,
      product_name: list?.[0].name,
      product_price: +list?.[0].price,
      product_quantity: list.length
    })), groupBy(prop('id')))(cart);

    setEventProperties("checkout", {
      id: nanoid(),
      total_price: parseFloat(order.total),
      currency: DENOMINATION,
      items: items
    });

    cart.forEach(item =>
      setEventProperties("orderComplete", {
        id: event_id,
        productID: item.id,
        product_name: item.name,
        product_price: +item.price,
        order_id: order.id,
        firstname: order.name,
        email: order.email,
        address: order.address,
        payment_mode: order.payment_mode,
        shoppingcart_value: parseFloat(order.total),
        recipient_email: order.receipt_email,
      })
    )
    setPageProperties({page_category:'Checkout',page_name:'Checkout',page_domain:isBrowser() && window.location.hostname})
    // setEventProperties('orderComplete',{ id: event_id, order_id:order.id,firstname:order.name,email:order.email,address:order.address,payment_mode:order.payment_mode,shoppingcart_value:parseFloat(order.total),recipient_email:order.receipt_email,})
    // TODO call API
    setOrderCompleted(true)
    clearCart()
  }

  const { numberOfItemsInCart, cart, total } = context
  const cartEmpty = numberOfItemsInCart === Number(0)

  if (orderCompleted) {
    return (
      <>
      <LogLink />
      <div>
        <h3>Thanks! Your order has been successfully processed.</h3>
      </div>
      </>
    )
  }

  return (
    <>
     <LogLink />
    <div className="flex flex-col items-center pb-10">
      <div
        className="
            flex flex-col w-full
            c_large:w-c_large
          "
      >
        <div className="pt-10 pb-8">
          <h1 className="text-5xl font-light">Checkout</h1>
          <ConditionalLink redirectUrl="/cart" onClick={()=>setEventProperties(`editCart`)} isMultiPageSite={getSiteConfigField('multiPageSite') === 'true' }>
            <div className="cursor-pointer flex">
              <FaLongArrowAltLeft className="mr-2 text-gray-600 mt-1" />
              <p className="text-gray-600 text-sm">Edit Cart</p>
            </div>
          </ConditionalLink>
        </div>

        {cartEmpty ? (
          <h3>No items in cart.</h3>
        ) : (
          <div className="flex flex-col">
            <div className="">
              {cart.map((item, index) => {
                return (
                  <div className="border-b py-10" key={index}>
                    <div className="flex items-center">
                      <Image
                        className="w-32 m-0"
                        src={item.image}
                        alt={item.name}
                      />
                      <p className="m-0 pl-10 text-gray-600 text-sm">
                        {item.name}
                      </p>
                      <div className="flex flex-1 justify-end">
                        <p className="m-0 pl-10 text-gray-900 tracking-tighter font-semibold">
                          {DENOMINATION + item.price}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className="flex flex-1 flex-col md:flex-row">
              <div className="flex flex-1 pt-8 flex-col">
                <div className="mt-4 border-t pt-10">
                  <form onSubmit={handleSubmit}>
                    {errorMessage ? <span>{errorMessage}</span> : ""}
                    <Input
                      onChange={onChange}
                      value={input.discount}
                      name="discount"
                      placeholder="Add a discount code"
                    />
                    <Input
                      onChange={onChange}
                      value={input.name}
                      name="name"
                      placeholder="Name"
                    />
                    {/* <CardElement className="mt-2 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" /> */}
                    {isGuest() ?
                      (<Input
                        onChange={onChange}
                        value={input.email}
                        name="email"
                        placeholder="Email"
                      />) : null
                    }
                    <Input
                      disabled='disabled'
                      value={input.address}
                      name="address"
                      placeholder="Address"
                    />
                    <Input
                      onChange={onChange}
                      value={input.payment_mode}
                      name="payment_mode"
                      placeholder="Payment Mode"
                    />
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="hidden md:block bg-secondary hover:bg-black text-white font-bold py-2 px-4 mt-4 rounded focus:outline-none focus:shadow-outline"
                    >
                      Confirm order
                    </button>
                  </form>
                </div>
              </div>
              <div className="md:pt-20">
                <div className="ml-4 pl-2 flex flex-1 justify-end pt-2 md:pt-8 pr-4">
                  <p className="text-sm pr-10">Subtotal</p>
                  <p className="tracking-tighter w-38 flex justify-end">
                    {DENOMINATION + Math.round(total)}
                  </p>
                </div>
                <div className="ml-4 pl-2 flex flex-1 justify-end pt-2 md:pr-4">
                  <p className="text-sm pr-10">Discount</p>
                  <p className="tracking-tighter w-38 flex justify-end">
                    {DENOMINATION + billingAmount.discount}
                  </p>
                </div>
                <div className="ml-4 pl-2 flex flex-1 justify-end pr-4">
                  <p className="text-sm pr-10">Shipping</p>
                  <p className="tracking-tighter w-38 flex justify-end">
                    FREE SHIPPING
                  </p>
                </div>
                <div className="md:ml-4 pl-2 flex flex-1 justify-end bg-gray-200 pr-4 pt-6">
                  <p className="text-sm pr-10">Total</p>
                  <p className="font-semibold tracking-tighter w-38 flex justify-end">
                    {DENOMINATION + billingAmount.total }
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
    </>
  )
}

export default CheckoutWithContext
